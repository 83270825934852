import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Alert,
  AlertIcon,
  Heading,
  Text,
  CircularProgress,
  Input,
  Image,
  IconButton,
  Grid,
  GridItem,
  SimpleGrid,
  OrderedList,
  ListItem,
  Center,
  Button,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react';
import Post from './Post';
import { createLoadingAndErrorSelector, postListSelector } from '../selectors';
import { getPostList } from '../actions/postList';
import { displayBig, width, displaySmall } from '../assets/constant'
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import ThemedBox from './ThemedBox';
import images from './image/image.svg'
import url from './image/url.svg'
import plus from './image/plus.svg'


const PostList = ({ isLoading, error, postList, getPostList }) => {
  const { subreddit } = useParams();
  const [items, setItems] = useState(["Lorem Ipsum is simply dummy text of the printing.", "Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum"])
  const bgColor = useColorModeValue('white', '#152441');
  const bgButton = useColorModeValue('#152441', 'white');

  useEffect(() => {
    getPostList({ subreddit });
  }, [getPostList, subreddit]);

  if (isLoading) {
    return (
      <Flex m={10} justifyContent="center" alignItems="center" >
        <CircularProgress isIndeterminate />
      </Flex>
    );
  } else if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }
  return (
    <Center>
      <SimpleGrid templateColumns={{ base: "1fr 95% 1fr", sm: "1fr 80% 1fr", md: "0px 70% 1fr", lg: "1fr 60% 1fr" }} gap={"2"} overflowX={"hidden"} zIndex={"0"} h={"fit-content"} w={"98%"}>
        <Box padding={{ base: "0", md: "5" }} w={"100%"} borderColor='#B7A46B'>

        </Box>
        <Box paddingY={"5"} paddingX={"0"} w={"100%"} >
          {/* <Heading>{subreddit ? `r/${subreddit}` : 'Home'}</Heading> */}
          <Flex padding={"1.5"} bg="white" borderRadius={"10"}>
            <Input placeholder='What to discuss?' display={"flex"} alignItems={"center"} h={"40px"} fontSize='xl' fontWeight='semiBold' backgroundColor='#E7E6E6' borderColor='#B7A46B' errorBorderColor='crimson' marginRight={"2"} />
            <IconButton p={"0.5"} marginRight={"1"} icon={<img src={images} />} alt='' colorScheme='clear' as={Link} to="/submit"/>
            <IconButton p={"0.5"} marginRight={"1"} icon={<img src={url} />} alt='' colorScheme='clear' as={Link} to="/submit"/>
            <IconButton p={"0.5"} icon={<img src={plus} />} alt='' colorScheme='clear' as={Link} to="/submit"/>
          </Flex>
          {postList.length > 0 ? (
            postList.map(
              ({
                id,
                type,
                title,
                body,
                created_at,
                votes,
                has_voted,
                number_of_comments,
                author_name,
                subreddit_name,
              }) => (
                <Box key={`${id}-${title}`} my={4}>
                  <Post
                    id={id}
                    type={type}
                    subreddit={subreddit_name}
                    author={author_name}
                    createdAt={created_at}
                    title={title}
                    body={body}
                    numComments={number_of_comments}
                    numVotes={votes}
                    hasVoted={has_voted}
                  />
                </Box>
              )
            )
          ) : (
            <Text m={5}>There are no posts to display.</Text>
          )}
        </Box>
        {/* <Box paddingRight={"3"} w={"100%"}>
          <Flex display={{ base: "none", md: "flex" }} w={"full"} flexDirection={"column"} marginBottom={"4"}>
            <Box bg={"#152441"} padding={"3"} border={"1px"} borderRadius={"6px 6px 0px 0px"}>
              <Text fontSize='xl' color={"white"} fontWeight="bold">Hot Topics</Text>
            </Box>
            <Box w={"full"} bg={bgColor} padding={"3"} border={"1px"} borderRadius={"0px 0px 6px 6px"} marginBottom={"4"}>
              <OrderedList>
                {items && items.map((val, index) => (
                  <ListItem textOverflow={"initial"} key={index}>
                    <Text wordBreak={"break-word"}>{val}</Text>
                  </ListItem>
                ))}
              </OrderedList>
              <Center marginTop={"10px"}>
                <Button colorScheme='blue' borderRadius={"full"}>More Topics</Button>
              </Center>
            </Box>
          </Flex>

          <Center display={{ base: "none", md: "flex" }} marginBottom={"4"} marginTop={"4"}>
            <Button colorScheme={"yellow"} color={"white"} border={"1px"} borderColor={"black"} borderRadius={"full"}>Become a Sobuer</Button>
          </Center>

          <Flex display={{ base: "none", md: "flex" }} w={"full"} marginBottom={"4"} flexDirection={"column"}>
            <Box bg={"#152441"} padding={"3"} border={"1px"} borderRadius={"6px 6px 0px 0px"}>
              <Text fontSize='xl' color={"white"} fontWeight="bold">New Groups</Text>
            </Box>
            <Box w={"full"} bg={bgColor} padding={"3"} border={"1px"} borderRadius={"0px 0px 6px 6px"}>
              <OrderedList>
                {items && items.map((val, index) => (
                  <ListItem textOverflow={"initial"} key={index}>
                    <Text wordBreak={"break-word"}>{val}</Text>
                  </ListItem>
                ))}
              </OrderedList>
              <Center marginTop={"10px"}>
                <Button colorScheme='blue' borderRadius={"full"}>More Groups</Button>
              </Center>
            </Box>
          </Flex>
        </Box> */}
      </SimpleGrid>
    </Center>
  );
};

const { loadingSelector, errorSelector } = createLoadingAndErrorSelector([
  'GET_POST_LIST',
]);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  postList: postListSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPostList: (filters) => dispatch(getPostList(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostList);

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Text,
  Flex,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Image,
  Input,
  useColorMode,
  useDisclosure,
  MenuDivider,
  Alert,
  AlertIcon,
  CircularProgress,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import ThemedBox from './ThemedBox';
import {
  userSelector,
  subredditsSelector,
  createLoadingAndErrorSelector,
} from '../selectors';
import { startLogout } from '../actions/auth';
import { getSubreddits } from '../actions/subreddits';
import LoginAndRegisterButtons from './LoginAndRegisterButtons';
import homeIcon from './image/home-icon.svg';
import logoSocialBureau from './image/LOGO-Social Bureau_white.svg';
import bell from './image/bell.svg'
import jandra from './image/Jandra-icon.svg'
import bgcheck from './image/backgound-check-icon.svg'
import thiefIcon from './image/thief-icon.svg'
import userIcon from './image/user-icon.svg'
import hamburger from './image/hamburger.svg'
import close from './image/close.svg'
import arrowRight from './image/arrowRight.svg'
import { displayBig, width, displaySmall } from '../assets/constant'

const Navbar = ({
  user,
  subreddits,
  isLoading,
  error,
  startLogout,
  getSubreddits,
}) => {
  const { isOpen: isSmallNavOpen, onOpen: onSmallNavOpen, onClose: onSmallNavClose } = useDisclosure();
  const location = useLocation();
  const subredditName = location.pathname.match(/r\/[^\/]+/);
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const [display, changeDisplay] = useState('none')

  useEffect(() => {
    getSubreddits();
  }, []);

  return (
    <>
      <Box
        py={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        position="fixed"
        w="full"
        bg={"#152441"}
        zIndex={500}
      >
        <Box 
          width="95%"
          // minWidth= "800px"
          display="flex"
          justifyContent="space-between"
          height="fit-content">

          <HStack spacing='20px'>
            <Box w={"fit-content"}>
            <a href="/"><Image height={"60px"} src={logoSocialBureau} alt="Logo" /></a>
            </Box>
            <Menu display={displayBig}>
              <MenuButton mx={2} as={Button} rightIcon={<ChevronDownIcon />} display={displayBig} colorScheme='clear' border='1px' borderColor='#B7A46B' color='#B7A46B'>
                {subredditName || 'Home'}
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} to="/">
                  Home
                </MenuItem>
                <MenuDivider />
                {subreddits.map(({ name }) => (
                  <MenuItem
                    key={name}
                    as={Link}
                    to={`/r/${name}`}
                  >{`r/${name}`}</MenuItem>
                ))}
                {error && (
                  <Alert status="error">
                    <AlertIcon />
                    Error fetching bureau
                  </Alert>
                )}
                {isLoading && (
                  <Flex justifyContent="center">
                    <CircularProgress isIndeterminate />
                  </Flex>
                )}
              </MenuList>
            </Menu>
            <Button display={displayBig} as={Link} to="/submit" colorScheme='clear' border='1px' borderColor='#B7A46B' color='#B7A46B'>
              Submit crime
            </Button>
            <InputGroup display={displayBig} w={{ sm: "100px", md: "200px", lg: "300px", xl: "350px" }}>
              <InputLeftElement
                pointerEvents='none'
                children={<SearchIcon color='gray.300' />}
              />
              <Input type='tel' placeholder='Search crime information' color='white'/>
            </InputGroup>
            {/* <Input placeholder='Search crime information' w={{ sm: "100px", md: "200px", lg: "300px", xl: "350px" }} h={"35px"} fontSize='xl' display={displayBig} fontWeight='semiBold' borderColor='white'  color='white' errorBorderColor='crimson' /> */}
            {/* <IconButton icon={<img src={bgcheck} />} alt='' colorScheme='clear' display={displayBig} /> */}
            {/* {user && (
              <IconButton icon={<img src={thiefIcon}/>} alt='' colorScheme='clear' as={Link} to="/submit" display={displayBig}/>
            )} */}
          </HStack>

          <HStack spacing='16px' display={displayBig}>
            <IconButton
              colorScheme={'clear'}
              aria-label={'Notifications'}
              size={'lg'}
              icon={<>
                <img src={bell} />
                <Box as={'span'} color={'white'} position={'absolute'} top={'4px'} right={'4px'} fontSize={'0.8rem'}
                  bgColor={'red'} borderRadius={'lg'} zIndex={200} p={'1px'}>
                  15
                </Box>
              </>}
            />
            <IconButton icon={<img src={jandra} />} alt='' colorScheme='clear' />
            {/* <IconButton icon={<img src={userIcon} />} alt='' colorScheme='clear' /> */}
            {user ? (
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} borderColor='#B7A46B' border='1px' borderRadius='3xl' colorScheme='clear' textColor='#B7A46B'>
                    {user.username}
                  </MenuButton>
                  <MenuList>
                    <MenuItem display={['block', 'none']} as={Link} to="/submit">
                      Submit post
                    </MenuItem>
                    <MenuItem as={Link} to="/subreddits/create">
                      Create bureau
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        await startLogout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <LoginAndRegisterButtons />
              )}
            <ColorModeSwitcher />
          </HStack>
          <HStack spacing='16px' display={{ base: "flex", md: "none" }} alignSelf={'center'}>
            <IconButton icon={isSmallNavOpen ? <img src={close} /> : <img src={hamburger} />} size={"md"} alt='' alignSelf={'center'} color={"white"} onClick={isSmallNavOpen ? onSmallNavClose : onSmallNavOpen} />
          </HStack>
        </Box>
        {isSmallNavOpen && <>
          <Box display={displaySmall} flexDirection={"column"} w={"full"} paddingX={"5"}>
            <Flex gap="4" marginTop={"3"}>
            <InputGroup >
              <InputLeftElement
                pointerEvents='none'
                children={<SearchIcon color='gray.300' />}
              />
              <Input type='tel' placeholder='Search crime information' color='white'/>
            </InputGroup>
              {/* <Input placeholder='Search crime information' marginRight={"5"} h={"35px"} fontSize='xl' fontWeight='semiBold' backgroundColor='#E7E6E6' borderColor='#B7A46B' errorBorderColor='crimson' onClick={onSmallNavClose}/> */}
              {/* <IconButton icon={<img src={bgcheck} />} alt='' colorScheme='clear' onClick={onSmallNavClose}/> */}
            </Flex>
            <Flex justifyContent={"space-between"} as={Link} to="/" alignItems={"center"} color={"white"} borderBottom={"2px"} borderColor={"blue.800"} _hover={{ paddingX: "10px", borderBottom: "2px", borderColor: "yellow.300" }}>
              <Text fontSize='lg' marginY={"3"} onClick={onSmallNavClose}>Home</Text>
              {/* <IconButton icon={<img src={arrowRight} />} alt='' colorScheme='clear' color={"white"} /> */}
            </Flex>
            <Flex justifyContent={"space-between"} as={Link} to="/submit" alignItems={"center"} color={"white"} borderBottom={"2px"} borderColor={"blue.800"} _hover={{ paddingX: "10px", borderBottom: "2px", borderColor: "yellow.300" }}>
              <Text fontSize='lg' marginY={"3"} onClick={onSmallNavClose}>Report Crime</Text>
              {/* <IconButton icon={<img src={arrowRight} />} alt='' colorScheme='clear' color={"white"} /> */}
            </Flex>
            <ColorModeSwitcher justifyContent={"space-between"} as={Link} alignItems={"center"} color={"white"} _hover={{ paddingX: "10px", borderBottom: "2px", borderColor: "yellow.300" }}/>
            <Box marginY={"3"}>
            {user ? (
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} borderColor='#B7A46B' border='1px' borderRadius='3xl' colorScheme='clear' textColor='#B7A46B' >
                    {user.username}
                  </MenuButton>
                  <MenuList>
                    <MenuItem display={['block', 'none']} as={Link} to="/submit" onClick={onSmallNavClose}>
                      Submit post
                    </MenuItem>
                    <MenuItem as={Link} to="/subreddits/create" onClick={onSmallNavClose}>
                      Create bureau
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        await startLogout();
                        onSmallNavClose();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <HStack>
                  <Menu>
                  <MenuButton
                    as={Button}
                    border='1px'
                    borderColor='#B7A46B' 
                    borderRadius='3xl'
                    colorScheme='clear'
                    textColor='#B7A46B' 
                  >
                  Connect
                  </MenuButton>
                  <MenuList>
                <MenuItem
                  as={Link}
                  to={{
                    pathname: '/login',
                    state: {
                      prevPathname: location.pathname,
                    },
                  }}
                  onClick={onSmallNavClose}
                  >
                    Login
                  </MenuItem>
                <MenuItem
                    as={Link}
                    to={{
                      pathname: '/register',
                      state: {
                        prevPathname: location.pathname,
                      },
                    }}
                    onClick={onSmallNavClose}
                    >
                      Register
                    </MenuItem>
              </MenuList>
                  </Menu>
                </HStack>
              )}
            </Box>
          </Box>
        </>}
      </Box>
    </>
  );
};

const { loadingSelector, errorSelector } = createLoadingAndErrorSelector([
  'GET_SUBREDDITS',
]);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  subreddits: subredditsSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
  getSubreddits: () => dispatch(getSubreddits()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

import { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Text,
  Heading,
  Box,
  Flex,
  HStack,
  Tooltip,
  IconButton,
  useColorMode,
  Button,
} from '@chakra-ui/react';
import { ChatIcon, EditIcon } from '@chakra-ui/icons';
import ThemedBox from './ThemedBox';
import UpvoteBar from './UpvoteBar';
import EditBox from './EditBox';
import DeleteButton from './DeleteButton';
import ChakraMarkdown from './ChakraMarkdown';
import { userSelector } from '../selectors';

const Post = ({
  id,
  type,
  subreddit,
  author,
  createdAt,
  title,
  body,
  numVotes,
  hasVoted,
  numComments,
  user,
}) => {
  const { colorMode } = useColorMode();
  const postDetailColor = 'gray.500';
  const postDetailBgColor = colorMode === 'light' ? 'gray.100' : 'gray.600';
  const isTextPost = type === 'text';
  const [showWriteReply, setShowWriteReply] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const deletedText = '[deleted]';
  const history = useHistory();
  const location = useLocation();

  return (
    <ThemedBox
      p={4}
      borderRadius="md"
      width="100%"
      light="white"
      dark="#152441"
      boxShadow='md'
      borderColor="#30528F"
      border='1px'
    >
      <Flex>
        <UpvoteBar
          type="post"
          numVotes={numVotes}
          id={id}
          voteValue={hasVoted}
        />
        <Box flexGrow={1}>
          <Text as="span" fontWeight="bold">{author ? `u/${author}` : deletedText}</Text>
          <Text as="span" color={postDetailColor}>
            {' '}
            <Tooltip label={moment(createdAt).format('LLLL')}>
              {moment(createdAt).fromNow()}
            </Tooltip>
          </Text>
          <Heading
            paddingLeft={"4"}
            as={isTextPost ? Link : 'a'}
            display="block"
            to={isTextPost ? `/r/${subreddit}/comments/${id}` : null}
            href={isTextPost ? null : body}
            target={isTextPost ? null : '_blank'}
            mt={2}
            mb={4}
            fontSize="1.5em"
            fontWeight="500"
            text-shadow="0 0 5px rgb(104 182 255 / 50%)"
            whiteSpace="unset"
            wordBreak="break-word"
          >
            {title || deletedText}
          </Heading>
          {isTextPost ? (
            isEditing ? (
              <EditBox
                paddingLeft={"8"}
                type="post"
                id={id}
                initialText={body}
                onClose={() => setIsEditing(false)}
                whiteSpace="unset"
                wordBreak="break-word"
              />
            ) : (
              <Box listStylePosition="inside"
                whiteSpace="unset"
                paddingLeft={"8"}
                wordBreak="break-word">
                <ChakraMarkdown>{body}</ChakraMarkdown>
              </Box>
            )
          ) : null}
        </Box>
        {user && user.username === author && (
          <HStack alignItems="flex-start">
            {isTextPost && !isEditing && (
              <IconButton
                onClick={() => setIsEditing(true)}
                backgroundColor="inherit"
                icon={<EditIcon />}
              />
            )}
            <DeleteButton type="post" id={id} />
          </HStack>
        )}
      </Flex>
      
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        color={postDetailColor}
        fontWeight="bold"
        flexWrap={"wrap"}
        
      >
      <Flex
        direction={"row"}
        alignItems="end"
        color={postDetailColor}
        fontWeight="bold"
        flexWrap={"wrap"}
        
      >
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"} 
            as={isTextPost ? Link : 'a'}
            to={isTextPost ? `/r/${subreddit}/comments/${id}` : null}
            href={isTextPost ? null : body}
            target={isTextPost ? null : '_blank'}>
          Reply
        </Box>
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"}>
          Give Badge
        </Box>
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"}>
          Tip
        </Box>
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"}>
          Bookmark
        </Box>
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"}>
          Report
        </Box>
        <Box fontSize={"sm"} _hover={{ bg: "gray.200" }} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"0.5"}>
          Share
        </Box>
      </Flex>
      {/* <Flex
        direction={"row"}
        alignItems="flex-end"
        color={postDetailColor}
        fontWeight="bold"
        flexWrap={"wrap"}
        
      >
        <Box fontSize={"sm"} bg={"#F4B183"} _hover={{ bg: "gray.200" }} color={"white"} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} borderColor={"gray.500"} paddingX={"9px"} paddingY={"4"} textAlign={"center"} paddingTop={"9px"}>
          Verification<br></br>Needed
        </Box>
        <Flex direction={"column"}>
          <Box fontSize={"sm"} bg={"#152441"} color={"#B7A46B"} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"3"} paddingY={"3"} paddingBottom={"1px"} paddingTop={"1px"} textAlign={"center"}>
            RS Vote
              <Flex direction={"row"} alignItems="center">
                <Button fontSize={"sm"}  bg={"#152441"} _hover={{ bg: "gray.200" }} marginBottom={"1"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"1"} textAlign={"center"}>
                  True
                </Button>
                <Button fontSize={"sm"} bg={"#152441"} _hover={{ bg: "gray.200" }}  marginBottom={"1"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} paddingX={"2"} paddingY={"1"} textAlign={"center"}>
                  False
                </Button>
            </Flex>
          </Box>
        </Flex>
        <Box fontSize={"sm"} bg={"#C9C9C9"} _hover={{ bg: "gray.200" }} color={"white"} marginBottom={"2"} marginRight={"2"} fontWeight={"medium"} border={"1px"} borderRadius={"md"} borderColor={"gray.500"} paddingX={"9px"} paddingY={"23px"} textAlign={"center"}>
          Dispute
        </Box>
      </Flex> */}
      </Flex>

      
    </ThemedBox>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
});

export default connect(mapStateToProps)(Post);

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import theme from './theme';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import CommentsPage from './components/CommentsPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import CreatePostPage from './components/CreatePostPage';
import PostList from './components/PostList';
import ThemedBox from './components/ThemedBox';
import CreateSubredditPage from './components/CreateSubredditPage';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ThemedBox w="100vw" light="#D4DBE3" dark="gray.800">
          <Navbar />
          <Flex justifyContent="center" pt="24">
            <Switch>
              <Route path="/r/:subreddit/comments/:id">
                <Box width={['95%', '80%', '70%', '60%']} mb={10}>
                  <CommentsPage />
                </Box>
              </Route>
              <PublicRoute path="/login" >
                <Box width={['95%', '80%', '70%', '60%']} mb={10}  h="100vh">
                  <LoginPage />
                </Box>
              </PublicRoute>
              <PublicRoute path="/register">
                <Box width={['95%', '80%', '70%', '60%']} mb={10}  h="100vh">
                  <RegisterPage />
                </Box>
              </PublicRoute>

              <PrivateRoute path="/submit">
                <Box mb={10}  h="100vh">
                </Box>
                <CreatePostPage />
              </PrivateRoute>
              <PrivateRoute path="/subreddits/create">
                <Box width={['95%', '80%', '70%', '60%']} mb={10}  h="100vh">
                  <CreateSubredditPage />
                </Box>
              </PrivateRoute>
              <Route path="/r/:subreddit">
                <PostList />
              </Route>
              <Route path="/">
                <Box width="100%" mb={10}>
                <PostList />
                </Box>
              </Route>
            </Switch>
          </Flex>
        </ThemedBox>
      </Router>
    </ChakraProvider>
  );
}

export default App;

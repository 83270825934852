import React, {
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Stack,
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
  RadioGroup,
  Radio,
  Select,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  createLoadingAndErrorSelector,
  subredditsSelector,
} from '../selectors';
import { getSubreddits } from '../actions/subreddits';
import { submitPost } from '../actions/post';
import Dropzone from 'react-dropzone-uploader'
import "react-dropzone-uploader/dist/styles.css";
import { string } from 'prop-types';

class CreatePostPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postType: string,
      title: '',
      body: '',
      img: '',
      url: '',
      subreddit: '',
    };
  }

  componentDidMount() {
    const { getSubreddits } = this.props;
    getSubreddits();
  }

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { postType, title, body, img, url, subreddit } = this.state;
      const { submitPost, history } = this.props;
      const { id } = await submitPost({
        type: postType,
        title,
        body: postType === string ? body : url,
        img: postType === string && img,
        subreddit,
      });
      history.push(`/r/${subreddit}/comments/${id}`);
    } catch (err) {}
  };

  render() {
    const { postType, title, body, img, url, subreddit } = this.state;
    const {
      srIsLoading,
      srError,
      submitIsLoading,
      submitError,
      subreddits,
    } = this.props;

    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => { return { url: 'https://api-crime.socialbureau.io' } }
      
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
      
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => { console.log(files.map(f => f.meta)) }

    return (
      <Box w={['100%', '90%', '80%', '70%']} m="rem">
        {submitError && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {submitError}
          </Alert>
        )}
        <form onSubmit={this.handleSubmit}>
          <Stack spacing={3}>
            <FormControl>
              <RadioGroup
                value={postType}
                onChange={(postType) => this.setState({ postType })}
              >
                <Stack direction="row" spacing={3} justifyContent="center">
                  <Radio value="text">text post</Radio>
                  <Radio value="link">link</Radio>
                  <Radio value="image">image & video & voice</Radio>
                  <Radio value="bounty">bounty hunting</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl>
              <Input
                value={title}
                onChange={(e) => this.setState({ title: e.target.value })}
                type="text"
                variant="filled"
                placeholder="title"
                boxShadow="md"
                isRequired
              />
            </FormControl>
            <FormControl>
              {postType === 'text' ? (
                <Textarea
                  value={body}
                  onChange={(e) => this.setState({ body: e.target.value })}
                  variant="filled"
                  placeholder="text (optional)"
                  boxShadow="md"
                  rows={10}
                />
              ) : (
                <Input
                  value={url}
                  onChange={(e) => this.setState({ url: e.target.value })}
                  type="url"
                  variant="filled"
                  placeholder="url"
                  boxShadow="md"
                  // required
                />
              )}
            </FormControl>
            <FormControl>
            {postType === 'image' && (
                <Textarea
                  value={body}
                  onChange={(e) => this.setState({ body: e.target.value })}
                  variant="filled"
                  placeholder="text (optional)"
                  boxShadow="md"
                  rows={10}
                />
              )}
              {
                postType === 'image' && (
                  <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                accept="image/*,audio/*,video/*"
              />
                )
              }
            </FormControl>
            <FormControl isInvalid={srError}>
              <Select
                value={subreddit}
                onChange={(e) => this.setState({ subreddit: e.target.value })}
                variant="filled"
                placeholder={srIsLoading ? 'loading...' : 'choose a subreddit'}
                boxShadow="md"
                isRequired
              >
                {subreddits.map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>Could not load subreddits</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              isLoading={srIsLoading || submitIsLoading || null}
              boxShadow="md"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    );
  }
}

const {
  loadingSelector: srLoadingSelector,
  errorSelector: srErrorSelector,
} = createLoadingAndErrorSelector(['GET_SUBREDDITS']);

const {
  loadingSelector: submitLoadingSelector,
  errorSelector: submitErrorSelector,
} = createLoadingAndErrorSelector(['SUBMIT_POST'], false);

const mapStateToProps = (state) => ({
  srIsLoading: srLoadingSelector(state),
  srError: srErrorSelector(state),
  submitIsLoading: submitLoadingSelector(state),
  submitError: submitErrorSelector(state),
  subreddits: subredditsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSubreddits: () => dispatch(getSubreddits()),
  submitPost: (postDetails) => dispatch(submitPost(postDetails)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatePostPage)
);

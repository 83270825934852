import { Link, useLocation } from 'react-router-dom';
import { HStack, Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';

const LoginAndRegisterButtons = () => {
  const location = useLocation();
  const { isOpen: isSmallNavOpen, onOpen: onSmallNavOpen, onClose: onSmallNavClose } = useDisclosure();
  return (
    <HStack>
      <Menu>
      <MenuButton
        as={Button}
        border='1px'
        borderColor='#B7A46B' 
        borderRadius='3xl'
        colorScheme='clear'
        textColor='#B7A46B' 
      >
       Connect
      </MenuButton>
      <MenuList>
    <MenuItem
      as={Link}
      to={{
        pathname: '/login',
        state: {
          prevPathname: location.pathname,
        },
      }}
      onClick={onSmallNavClose}
      >
        Login
      </MenuItem>
    <MenuItem
        as={Link}
        to={{
          pathname: '/register',
          state: {
            prevPathname: location.pathname,
          },
        }}
        onClick={onSmallNavClose}
        >
          Register
        </MenuItem>
  </MenuList>
      </Menu>
    </HStack>
  );
};

export default LoginAndRegisterButtons;
